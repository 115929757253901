import Features from "./../components/features";
import Pricing from "../components/pricing";
import Question from "../components/question"
import image1 from "./../assets/images/Group 469305 (1).svg";
import { useState,useEffect } from "react";
import {Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import SwiperCore from "swiper"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import TrendContent from "./trend_content";
import WhyChooseUs from "./../components/why_choose_us";
import Cards from "./cards"
import card1 from "./../assets/images/card1.png"
import card2 from "./../assets/images/card2.png"
import card3 from "./../assets/images/card3.png"
import card4 from "./../assets/images/card4.png"
import card5 from "./../assets/images/card5.png"
import card6 from "./../assets/images/card6.png"
import card7 from "./../assets/images/card7.png"
import card8 from "./../assets/images/card8.png"
import card9 from "./../assets/images/card9.png"
import x from "./../assets/images/x.svg"
import instagram from "./../assets/icons/Instagram.svg"
import Telegram from "./../assets/icons/Telegram.svg"
import TikTok from "./../assets/icons/Tik Tok.svg"
import Whatsapp from "./../assets/icons/Whatsapp.svg"
import Youtube from "./../assets/icons/Youtube.svg"
// import instagram from "./../assets/images/instagram.svg"

var people_list=[
    {name:"Olivia Rhye" , job:"Founder & CEO"},
    {name:"Olivia Rhye" , job:"Founder & CEO"},
    {name:"Olivia Rhye" , job:"Founder & CEO"},
    {name:"Olivia Rhye" , job:"Founder & CEO"},
    {name:"Olivia Rhye" , job:"Founder & CEO"},
    {name:"Olivia Rhye" , job:"Founder & CEO"},
]

var cards_list = [
    {border:"border-[#00D95F]",image:card1,icon:Whatsapp},
    {border:"border-[#FF0000]",image:card2,icon:x},
    {border:"border-black",image:card3,icon:x},
    {border:"border-[#FF0000]",image:card4,icon:Youtube},
    {border:"border-black",image:card5,icon:TikTok},
    {border:"border-[#FF7900]",image:card6,icon:x},
    {border:"border-[#FB00FF]",image:card7,icon:instagram},
    {border:"border-[#FF0000]",image:card8,icon:Telegram},
]

var trends_list = ["Valentine's Day campaign","happy valentin...","happy valentin...","happy valentin...","happy valentin...","happy valentin..."]

function Creator (props){
    useEffect(()=>{

    if (props.scroll===true) {
        const section=document.getElementById("main");
        section.scrollIntoView({behavior:"smooth"})
        
    }
},[props.scroll])

    const [isOpen ,setIsOpne] = useState(false);
    const [button, setButton] = useState("more");


 return(
    <>
        <div className="flex relative md:flex-row flex-col-reverse gap-10 md:gap-0 px-5 md:pl-20  justify-between">
        <div id="main"className=" absolute top-[-100px]"></div>
           
            <div className="flex flex-col gap-4 md:w-[40%]">
                <b className="text-4xl text-brand600">CONTENTOR</b>
                <b className="text-4xl text-gray900">the easiest way to create social media content</b>
                <div>
                    <form className="flex flex-col gap-3">
                        <div className="flex gap-4">
                            <input className="outline-none p-3 w-full border-2 border-gray300 rounded-xl" type="text" placeholder="Your name"></input>
                            <input className="outline-none p-3 w-full border-2 border-gray300 rounded-xl" type="text" placeholder="Your email"></input>
                        </div>
                        {/* <input type=""></input> */}
                        <select className="outline-none p-3 w-full border-2 border-gray300 rounded-xl bg-white" name="social media">
                            <option className="hover:bg-brand700" value="Select a social media" selected>Select a social media</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Youtube">Youtube</option>
                            <option value="TikTok">TikTok</option>
                            <option value="Telegram">Telegram</option>
                            <option value="Whats app">Whats app</option>
                            <option value="Whats app">X</option>
                        </select>
                        <textarea placeholder="Your Content idea" className="h-[120px] p-3 border-2 border-gray-200 rounded-lg outline-none w-full resize-none"></textarea>
                        <div className="flex items-center flex-wrap gap-4 text-gray600">
                            <p>Trends: </p>
                            {trends_list.map(
                                (item,index)=>{
                                    if (isOpen===false && index<2) {
                                        return(
                                            <div onClick={()=>textArea_controler(item)} className="transition-all duration-300 cursor-pointer flex items-center justify-center bg-brand50 rounded-xl gap-3 p-1">
                                            <div className=" w-2 h-2 bg-brand500 rounded-full"></div>
                                            <p className="text-brand700">{item}</p>
                                            </div>
                                        )
                                    }
                                    else if(isOpen===true){
                                        return(
                                            <div onClick={()=>textArea_controler(item)} className="transition-all duration-300 cursor-pointer flex items-center justify-center bg-brand50 rounded-xl gap-3 p-1">
                                            <div className=" w-2 h-2 bg-brand500 rounded-full"></div>
                                            <p className="text-brand700">{item}</p>
                                            </div>
                                        )
                                    }
                                }
                            )}
                            <div onClick={()=>onclick()} className="cursor-pointer flex items-center justify-center bg-brand50 rounded-xl gap-3 p-1">
                                <div className=" cursor-pointer text-brand700">{button}</div>
                            </div>
                            
                        </div>
                        <button className=" w-full bg-brand600 py-3 rounded-md text-white duration-500 hover:scale-[1.03] hover:shadow-lg font-bold ">Run</button>
                    </form>
                </div>
            </div>

            <div className='w-full md:hidden flex'>
            <Swiper
                    modules={[ Pagination,Autoplay, Scrollbar, A11y]}
                    loop={true}
                    slidesPerView={2}
                    observer={true}
                    observeParents={true}
                    autoplay={{
                        delay:0,
                        disableOnInteraction:false,
                        pauseOnMouseEnter:true

                    }}
                    speed={3000}
                    className=" hidden w-full  justify-center gap-10"
                >   

                    
                {cards_list.map((item)=>{
                    return(
                        <SwiperSlide className="flex justify-center text-center ml-3">
                            <Cards  border={item.border} image={item.image} icon={item.icon}/>

                        </SwiperSlide>

                    )
                })}
            
                </Swiper>
            </div>

            <div className="md:flex hidden relative">
                <div className=" flex items-end gap-4 sticky top-[-100px] right-0 transform rotate-[-25deg]">
                    <div className="md:flex-col flex-row flex gap-6">
                        <Cards id="animation1" border="border-[#00D95F]" image={card1} icon={Whatsapp}/>
                        <Cards id="animation2" border="border-[#FF0000]" image={card2} icon={x}/>
                    </div>
                    <div className="md:flex-col flex-row flex gap-6">
                        <Cards id="animation3" border="border-black" image={card3} icon={x}/>
                        <Cards id="animation4" border="border-[#FF0000]" image={card4} icon={Youtube}/>
                        <Cards id="animation5" border="border-black" image={card5} icon={TikTok}/>
                        <Cards id="animation6" border="border-[#FF7900]" image={card6} icon={x}/>


                    </div>
                    <div className="md:flex-col flex-row flex gap-6">
                         <Cards id="animation5" border="border-[#FB00FF]" image={card7} icon={instagram}/>
                         <Cards id="animation5" border="border-[#34AADF]" image={card8} icon={Telegram}/>
                         <Cards id="animation5" border="border-[#FF0000]" image={card9} icon={x}/>
                        

                    </div>

                </div>
            </div>
                <div className="w-full h-[200px] md:inline-block hidden bg-white absolute left-0 bottom-[-100px] ">
                    <div className='w-full flex justify-center'>
                    <div className='sm:w-[90%] z-[5] w-[90%] h-[1.5px] bg-gray200  content-center'></div>
                    </div>
                </div>
        </div>
    </>
 )
 function onclick(params) {
    if (button === "more") {
        setButton("collapse")
        setIsOpne(true)
    }else{
        setButton("more")
        setIsOpne(false)

    }
}

function textArea_controler(props) {
    document.querySelector("textarea").innerHTML=props
}
}

export default Creator
