import {Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import SwiperCore from "swiper"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import { useEffect, useState } from "react";
import axios from "axios";
import api from "./api.json";
import { Link } from "react-router-dom";

var Calender_list = [
    {date:16,month:"oct",day:"Monday"},
    {date:17,month:"oct",day:"Tuesday"},
    {date:18,month:"oct",day:"Wednesday"},
    {date:19,month:"oct",day:"Thursday"},
    {date:20,month:"oct",day:"Friday"},
    {date:21,month:"oct",day:"Saturday"},
    {date:22,month:"oct",day:"Sunday"},
];

var trends_list=[
    {title:"Halloween" , caption:"Get ready for a spooky good time this Halloween! Dress up in your favorite costume and indulge in sweet treats, creepy decorations, and thrilling scares. Don't miss out on the fun!"},
    {title:"Saci Day" , caption:"Saci Day celebrates a mischievous figure from Brazilian folklore, Saci-Pererê, who stirs up fun and magic with his legendary antics."},
    {title:"World Cities Day" , caption:"World Cities Day is a day that focuses on the future of urbanization and its impact on our lives."},
    {title:"Reformation Day" , caption:"Reformation Day marks a momentous shift in the religious landscape. It highlights a turning point when ideas about faith and worship changed dramatically."},
    {title:"National Caramel Apple Day" , caption:"That delightful combo of sticky sweetness covering crisp fruit on a stick, it's like a carnival treat that puts smiles on faces."},
]

function Calender() {
    const[selected,setSelected]=useState(16)
    return(
        <>
            <div className="w-full sm:flex hidden sm:px-20 justify-center gap-8 mt-10">
                {Calender_list.map((item)=>{
                    return(
                    <div onClick={()=> select_controler(item.date)} className={` ${selected===item.date?"gradient text-brand50":"bg-brand50 text-brand900"} cursor-pointer w-[150px] shadow-lg flex text-center flex-col gap-4  px-8 py-8 rounded-2xl duration-300 transition transform hover:-translate-y-[2px] `}>
                        <p className="font-bold text-xl">{item.date}</p>
                        <p className="font-bold text-xl">{item.month}</p>
                        <p>{item.day}</p>
                    </div>
                    )
                })}
            </div>
            <div className='w-full md:hidden flex px-5 justify-center gap-8 mt-10'>
                <Swiper
                    modules={[ Pagination,Autoplay, Scrollbar, A11y]}
                    slidesPerView={2.2}
                    observer={true}
                    observeParents={true}
                    className='h-[200px]'
                >   
                    
{Calender_list.map((item)=>{
                    return(
                    <SwiperSlide className="flex justify-center text-center">
                    <div onClick={()=> select_controler(item.date)} className={` ${selected===item.date?"bg-brand700 text-brand50":"bg-brand50 text-brand900"} cursor-pointer w-[130px] shadow-lg flex text-center flex-col gap-4  px-8 py-8 rounded-2xl duration-300 transition`}>
                        <p className="font-bold text-xl">{item.date}</p>
                        <p className="font-bold text-xl">{item.month}</p>
                        <p>{item.day}</p>
                    </div>
                    </SwiperSlide>
                    )
                })}
            
                </Swiper>
            </div>
        </>
    )

    function select_controler(params) {
        setSelected(params)
    }
}


function Trends() {
    return(
        <>
        <div className="flex flex-col gap-8 justify-centers items-center mt-40">
        {trends_list.map((item,index)=>{
            return(
                <div className="flex sm:w-[90%] justify-between items-center sm:px-[9vw] px-4  ">
                <div className="flex items-center gap-5 sm:w-[60%]" >
                    <p className="flex justify-center items-center bg-brand50 text-brand600 w-[20px] h-[20px] p-4 rounded-md font-bold">{index+1}</p>
                    <div className="flex flex-col gap-3 text-gray600">
                        <b className="flex justify-between items-center">{item.title} 
                            <Link to="" className="text-brand700 sm:hidden inline-block">Use</Link>
                        </b>
                        <p>{item.caption}</p>
                    </div>
                </div>
                <Link className=" w-[80px] h-[40px] font-bold hidden sm:flex justify-center items-center text-white bg-brand600 px-4 py-2 rounded-lg hover:shadow-lg duration-300 transition transform hover:-translate-y-[2px] " alt="get started" to=""><button>Use</button></Link>
                
            </div>
            )
        })}
        </div>
        </>
    )
}

function Main() {
    const [data,setData] = useState(null);
    useEffect(()=>{
        axios.get('https://date.nager.at/api/v3/AvailableCountries')
        .then(response => setData(response.data))
        .catch(error => console.error(error))
        
    })


return(
    <>
    <div className='w-full md:hidden inline-block flex justify-center'>
    <div className='sm:w-[80%] z-50 w-[90%] h-[1.5px]  bg-gray200 mt-10 content-center'></div>

    </div>
    <div id="trends" className="w-full sm:px-20 px-4 flex justify-between items-center mt-20 sm:mt-40">
        <p className="font-bold sm:text-2xl text-gray900">Trend content of day</p>

        <select className="outline-none p-3 sm:w[400px] w-[150px] border-2 border-gray300 rounded-xl bg-white" name="social media">
                 <option value="CA" selected>Canada</option>
                {
                    api.map(
                        (item)=>{
                            return <option value={item.contryCode}>{item.name}</option>
                        }
                    )
                }
        </select>
    </div>

    <Calender/>
    <Trends/>

</>
)
}

export default Main