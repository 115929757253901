import logo from "./../assets/icons/logo.svg"
import menu from "./../assets/icons/menu.svg"
import { Link } from "react-router-dom";
import close from "./../assets/icons/close.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

var menu_names=[
    {name:"Home",link:'/'},
    {name:"Why choose us",link:'/#why_choose_us'},
    {name:"Features",link:'/#features'},
    {name:"Pricing",link:'/#pricing'},
    {name:"FAQ",link:'/#faq'},
    {name:"Contact us",link:'contact-us'},
    {name:"About us",link:'about-us'},
]

function Header() {
    const navigate = useNavigate()
    const [isOpen,setIsOpen] = useState(false)
    return(
        <>
        <div className={`${!isOpen?"flex":"hidden"} px-7vw shadow pt-4 pb-2 flex justify-between items-center sticky top-0 w-full bg-white z-10`}>
            <div className="flex justify-center items-center gap-3vw">
                <Link to="/" className="flex justify-center items-center gap-1vw">
                    <img src={logo} alt="i"/>
                    <p>CONTENTOR</p>
                </Link>

                <ul className=" hidden sm:flex gap-2vw text-gray600">
                    {
                        menu_names.map((item)=>{return <li><Link className="hover:underline hover:text-stone-700" to={item.link} smooth={true} duration={true} alt={item.name}>{item.name}</Link></li>})
                    }
                </ul>

            </div>
                <Link className="hidden sm:flex justify-center items-center text-white bg-brand600 px-4 py-2 rounded-lg hover:shadow-lg duration-300 transition transform hover:-translate-y-[2px] " alt="get started" to="/signup"><button>Get started</button></Link>
            <button onClick={()=>{control_menu()}} className=" sm:hidden inline-block">
                <img src={menu} alt="menu"/>
            </button>
        </div>
        {/* for mobile */}
        <div className={`h-[100vh] ${isOpen?"flex":"hidden"} top-0 right-0 fixed z-30 bg-white w-full`}>
        <div className={` w-full h-[90vh] px-4 py-4 flex flex-col justify-between`}>
            <div className="flex justify-between items-center">
                    <Link to="/" className="flex justify-center items-center gap-1vw">
                    <img src={logo} alt=""/>
                    <p>CONTENTOR</p>
                    </Link>
                <button onClick={()=>{control_menu()}}><img src={close}/></button>
            </div>

            <div>
                <ul className=" gap-8 flex flex-col ga text-gray600">
                    {
                    menu_names.map((item)=>{return <li><Link onClick={()=>{control_menu()}} className="hover:underline text-gray900 font-bold" to={item.link} alt={item.name}>{item.name}</Link></li>})
                    }
                </ul>
            </div>
            <Link to="/signup" onClick={()=>{control_menu()}}>
            <button className=" w-full bg-brand600 py-2 rounded-md text-white duration-500 hover:scale-[1.03] hover:shadow-lg  " type="submit">Get started</button>
                    
            </Link>

        </div>
        </div>
    </>
    );
    function control_menu() {
        if (!isOpen) {
            setIsOpen(true);
            document.body.style.overflow="hidden";
        }else{
            setIsOpen(false)
            document.body.style.overflow="auto";


        }
    }


}

function Header_prime(params) {
    return(
        <>
        <div className={` sm:px-10 px-4 pt-4 pb-2 flex justify-between items-center sticky top-0 w-full bg-white z-10`}>
                <Link to="/" className="flex justify-center items-center gap-1vw">
                    <img src={logo} alt="i"/>
                    <p>CONTENTOR</p>
                </Link>

        </div>
    </>
    );
}

function main(props) {
    return props.status==="prime"?<Header_prime/>:<Header/>
    
}

export default main