import image_contentor from "./../assets/images/Image.png";

function contactUs() {
    return(
        <div className="w-full flex flex-row justify-center items-center p-9 gap-x-3vw">
            <div className="flex flex-col gap-y-5 w-full sm:w-[40vw]">
                <div>
                    <p className=" text-gray900 font-black sm:text-3xl text-4xl">Get in touch</p>
                    <p className="text-gray600">Our friendly team would love to hear from you.</p>
                </div>
                <div className="flex flex-col gap-[15px]">
                    <div className="flex flex-col items-center justify-center sm:flex-row gap-x-2vw ">
                        <div className="w-full">
                            <p className="text-gray700">Name</p>
                            <input className=" w-full p-3 border-2 border-gray300 rounded-lg outline-none" placeholder="Full name" ></input>
                        </div>
                        
                        <div className="w-full">
                            <p className="text-gray700">Email</p>
                            <input className="w-full p-3 border-2 border-gray300 rounded-lg outline-none" placeholder="you@company.com" ></input>
                        </div>
                    </div>
                    <div className="">
                        <p className="text-gray700">Phone number</p>
                        <input className="p-3 border-2 border-gray300 rounded-lg outline-none w-full" placeholder="+1 (555) 000-0000" ></input>
                    </div>
                    <div>
                        <p className="text-gray700">Message</p>
                        {/* <input className="h-[120px] p-3 border-2 border-gray-200 rounded-lg outline-none w-full"  ></input> */}
                        <textarea className="h-[120px] p-3 border-2 border-gray-200 rounded-lg outline-none w-full resize-none"></textarea>
                    </div>
                    <div className="flex gap-x-2">
                        <input id="checkbox" type="checkbox" className="cursor-pointer accent-brand600 w-[15px]"></input> 
                        <label htmlFor="checkbox" className="text-gray600">You agree to our friendly <a href="#" className="underline">privacy policy</a>.</label>
                    </div>
                    <div>
                        <button className=" w-full bg-brand600 py-2 rounded-md text-white duration-500 hover:scale-[1.03] hover:shadow-lg ">send message</button>
                    </div>
                </div>

            </div>
                <img src={image_contentor} alt="contactUs_image" className="h-[30vw] hidden sm:inline-block"/>
        </div>
    );
}

export default contactUs;