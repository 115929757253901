import { Link } from "react-router-dom"

function signUp() {
    return(
        <div className="w-full flex justify-center py-4 sm:h-auto h-[85vh] items-center mb-12">
            <div className="flex flex-col gap-4 sm:w-[80%] max-w-[400px] w-[90%]">
                <div className="flex gap-1 flex-col">
                    <p className="text-gray900 font-bold text-2xl">Sign up</p>
                    <p className=" text-l text-gray-500">Start using your powerfull content gen.</p>
                </div>

                <form className="flex flex-col gap-2">
                    <div className="flex flex-col gap-2">
                        <label className="text-gray700 font-bold" htmlFor="name">Username*</label>
                        <input className="outline-none p-3 w-full border-2 border-gray300 rounded-xl" id="name" placeholder="Enter your username" type="text"></input>
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-gray700 font-bold" htmlFor="email">Email*</label>
                        <input className="outline-none p-3 w-full border-2 border-gray300 rounded-xl" id="email" placeholder="Enter your email" type="text"></input>
                    </div>
                    <div className="flex flex-col gap-2">
                        <label className="text-gray700 font-bold" htmlFor="pass">Password*</label>
                        <input className="outline-none p-3 w-full border-2 border-gray300 rounded-xl" id="pass" placeholder="Create a password" type="password"></input>
                        <input className="outline-none p-3 w-full border-2 border-gray300 rounded-xl" id="pass" placeholder="Confirm password" type="text"></input>
                    </div>
                    <small className="text-gray-500">Must be at least 8 characters.</small>

                    <button className=" w-full bg-brand600 mt-6 py-2 rounded-md text-white duration-500 hover:scale-[1.03] hover:shadow-lg" type="submit">Create account</button>
                    <p className="text-center mt-6 text-gray600">Already have an account? <Link className=" cursor-pointer" to={"/signin"}><b className="text-brand600">Sign in</b></Link></p>
                </form>
            </div>

        </div>
    )
}

export default signUp