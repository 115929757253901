import {Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import SwiperCore from "swiper"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import AboutUs from "../components/aboutus"
import Objectives from "../components/project_objectives"
import Avatar from "./../assets/images/amar.png"
import team1 from "./../assets/images/team1.png"
import team2 from "./../assets/images/team2.png"
import team3 from "./../assets/images/team3.png"
import team4 from "./../assets/images/team4.png"
import team5 from "./../assets/images/team5.png"
import team6 from "./../assets/images/team6.png"
import team7 from "./../assets/images/team7.png"
import team8 from "./../assets/images/team8.png"

var people_list=[
    {name:"Olivia Rhye" , job:"Founder & CEO" , icon:team1},
    {name:"Phoenix Baker" , job:"Engineering Manager" , icon:team2},
    {name:"Lana Steiner" , job:"Product Manager" , icon:team3},
    {name:"Demi Wilkinson" , job:"Frontend Developer" , icon:team4},
    {name:"Candice Wu" , job:"Backend Developer" , icon:team5},
    {name:"Natali Craig" , job:"Product Designer" , icon:team6},
    {name:"Drew Cano" , job:"UX Researcher" , icon:team7},
    {name:"Orlando Diggs" , job:"Customer Success" , icon:team8},
]

function OurTeam() {
    return(
        <div className="flex flex-col items-center justify-center gap-4 mt-20 px-4">
            <small className="text-brand600 font-bold">Our team</small>
            <p className="font-bold text-3xl text-gray900">Meet our team</p>
            <p className="text-gray600 sm:w-70% text-center mb-20">Our philosophy is simple — hire a team of diverse, passionate people and foster a culture that empowers you to do you best work.</p>
            
            <div className=" md:grid md:grid-cols-4 sm:grid sm:grid-cols-3 hidden flex-wrap items-center justify-center gap-y-10 gap-x-36">
                {people_list.map((item)=>{
                    return(
                        <div className="flex flex-col justify-center items-center gap-4">
                            <img className="w-[60px] rounded-[50%] object-cover" src={item.icon}/>
                            <p className="font-bold text-gray900">{item.name}</p>
                            <p className="text-brand700">{item.job}</p>
                        </div>
                    )
                })}

            </div>

            <div className='w-full sm:hidden flex'>
            <Swiper
                    modules={[ Pagination,Autoplay, Scrollbar, A11y]}
                    loop={false}
                    slidesPerView={2.5}
                    observer={true}
                    observeParents={true}
    
                    className=" hidden w-full  justify-center gap-10"
                >   

                    
                {people_list.map((item)=>{
                    return(
                        <SwiperSlide className="flex justify-center text-center">
                        <div className="flex flex-col justify-center items-center">
                            <img className="w-[60px] rounded-[50%] object-cover" src={item.icon}/>
                            <p className="font-bold">{item.name}</p>
                            <p className="text-brand700">{item.job}</p>
                        </div>
                        </SwiperSlide>

                    )
                })}
            
                </Swiper>
            </div>

            </div>

    )
}

function Usagestatistics() {
    return(
        <>
        <div className="flex gap-8 sm:px-[10vw] mt-40 sm:flex-row flex-col-reverse justify-center items-center px-4">
                <img className="sm:w-[45%] w-[100%] rounded-md" src={Avatar}/>
            <div className="flex flex-col justify-around sm:gap-28 gap-14">
                <div className='flex flex-col gap-4'>
                <small className="text-brand700">We’ve helped hundreds of companies</small>
                <p className="font-bold text-gray900 text-4xl"> We’re only just getting started on our journey</p>
                <p className=" text-gray500 text-xl sm:hidden inline-block">Everything you need to build modern UI and great products. We’ve done all the heavy lifting so you don’t have to — the perfect starting point for any project.</p>
                </div>
                <div className="grid gap-6 sm:grid-cols-2 grid-cols-1">
                    <div>
                        <p className="text-brand600 font-bold text-5xl">400+</p>
                        <small className="text-gray900">Projects completed</small>
                    </div>
                    <div>
                        <p className="text-brand600 font-bold text-5xl">600%</p>
                        <small className="text-gray900">Return on investment</small>
                    </div>
                    <div>
                        <p className="text-brand600 font-bold text-5xl">10K</p>
                        <small className="text-gray900">Global downloads</small>
                    </div>
                    <div>
                        <p className="text-brand600 font-bold text-5xl">200+</p>
                        <small className="text-gray900">5-star reviews</small>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

function main() {
    return(
        <div className="flex flex-col mt-20">
            <AboutUs/>
            <Objectives/>
            <OurTeam/>
            <Usagestatistics/>

        </div>
    )
};

// {people_list.map((item)=>{
//     return(
//         <SwiperSlide className="felx justify-center text-center">
//         <div className="flex flex-col justify-center items-center gap-4">
//             <img className="w-[60px] rounded-[50%] object-cover" src={item.icon}/>
//             <p className="font-bold">{item.name}</p>
//             <p className="text-brand700">{item.job}</p>
//         </div>
//         </SwiperSlide>

//     )
// })}
export default main