import mouse from "./../assets/icons/mouse.svg"
import watch from "./../assets/icons/watch.svg"
import increasing from "./../assets/icons/increasing.svg"
import sun from "./../assets/icons/sun.svg"

var objectives_list=[
    {title:"Simplify Content Creation" ,caption:"Make the process of creating social media content as easy as possible." ,icon:mouse},
    {title:"Enhance Content Quality" ,caption:"Leverage AI to generate creative and engaging content ideas." ,icon:sun},
    {title:"Save Time and Resources" ,caption:"Increase Social Media Engagement" ,icon:watch},
    {title:"Increase Social Media Engagement" ,caption:"Help users grow their online presence and reach a wider audience." ,icon:increasing},
]

function objectives(params) {
    return(
    <div className="flex flex-col items-center justify-center gap-4 mt-20 sm:w-full py-20 sm:px-0 px-4 bg-gray50">
        <small className="text-brand600 font-bold">Project Objectives</small>
        <p className="font-bold text-2xl text-gray900 mb-10">Why We're Here</p>
        <div className="flex sm:flex-row sm:gap-[3vw] gap-14 flex-col flex-wrap justify-center items-center">
            {objectives_list.map(
                (item)=>{
                    return(
                    <div className="flex flex-col gap-2 bg-brand100 items-start rounded-xl justify-between p-6 sm:w-[250px] w-full h-[230px] shadow-xl transform hover:translate-y-[-5px] transition ">
                        <img className="h-[20px]" src={item.icon} />
                        <p className="text-xl text-gray900">{item.title}</p>
                        <p className="text-gray600">{item.caption}</p>
                    </div>)})
            }
        </div>
    </div>
    )
}

export default objectives