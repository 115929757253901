import {Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import SwiperCore from "swiper"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
import feature_1 from "./../assets/icons/feature_1.svg"
import feature_2 from "./../assets/icons/feature_2.svg"
import feature_3 from "./../assets/icons/feature_3.svg"
import feature_4 from "./../assets/icons/feature_4.svg"
import feature_5 from "./../assets/icons/feature_5.svg"
import left_arrow from "./../assets/icons/left_arrow.svg"
import right_arrow from "./../assets/icons/right_arrow.svg"
import Avatar from "./../assets/images/Avatar.png"
import team1 from "./../assets/images/team1.png"
import team2 from "./../assets/images/team2.png"
import team3 from "./../assets/images/team3.png"
import team4 from "./../assets/images/team4.png"
import team5 from "./../assets/images/team5.png"
import team6 from "./../assets/images/team6.png"
import team7 from "./../assets/images/team7.png"
import team8 from "./../assets/images/team8.png"
import { useState } from 'react';
SwiperCore.use([Navigation])


var people_list=[
    {name:"Olivia Rhye" , job:"Founder & CEO" , image:team1,comment:"We’ve been using Contentor to kick start every new Social Media Post and can’t imagine working without it."},
    {name:"Phoenix Baker" , job:"Engineering Manager" , image:team2,comment:"This program has been very useful to me."},
    {name:"Lana Steiner" , job:"Product Manager" , image:team3,comment:"We’ve been using Contentor to kick start every new Social Media Post and can’t imagine working without it."},
    {name:"Demi Wilkinson" , job:"Frontend Developer" , image:team4,comment:"This program has been very useful to me."},
    {name:"Candice Wu" , job:"Backend Developer" , image:team5,comment:"We’ve been using Contentor to kick start every new Social Media Post and can’t imagine working without it."},
    {name:"Natali Craig" , job:"Product Designer" , image:team6,comment:"This program has been very useful to me."},
    {name:"Drew Cano" , job:"UX Researcher" , image:team7,comment:"We’ve been using Contentor to kick start every new Social Media Post and can’t imagine working without it."},
    {name:"Orlando Diggs" , job:"Customer Success" , image:team8,comment:"This program has been very useful to me."},
]


var features_list=[
    {name:"User Account",caption:"Users need to register and create a user account on the Contentor website to access its services.",icon:feature_1},
    {name:"Uploading Logo",caption:"Users may upload their unique logo, which will be incorporated into the generated content.",icon:feature_2},
    {name:"Inputting Preferred Text",caption:"Users may enter their desired text related to the expected content. This text will help the AI system generate relevant content.",icon:feature_3},
    {name:"Choosing Social Media Platform",caption:"Users can select their preferred social media platform from the list of supported networks, including Twitter, TikTok, WhatsApp, Instagram, Snapchat, and YouTube.",icon:feature_4},
    {name:"Content Presentation and Editing",caption:"After creating the content, it will be presented to the user. They can then edit different aspects of the generated file, such as hashtags, captions, and other elements, to suit their requirements.",icon:feature_5},
    
]
 

function Features_slider (){
    const [comment,setComment] = useState(people_list[0].comment)
    const handleSlide = (swiper)=>{
        // console.log(swiper.activeIndex)
        const activeIndex = swiper.activeIndex;
        setComment(people_list[activeIndex].comment)
    }
    return(
        <div className='w-full flex justify-center py-16 bg-gray50'>
        <div className="sm:w-[70%] w-full flex flex-col gap-10 p-4 ">
                
<p className="text-center font-bold text-gray900 sm:text-3xl ">{comment}</p>
            {/* slider -----> */}
            <div className='flex flex-row'>
                <button className='custom-next'><img src={left_arrow}/></button>
                <Swiper
                    loo
                    onSlideChange={handleSlide}
                    modules={[ Pagination,Autoplay, Scrollbar, A11y]}
                    loop={true}
                    slidesPerView={1}
                    autoplay={{
                        delay:5000,
                        disableOnInteraction:false
                    }}
                    observer={true}
                    observeParents={true}
                    onSwiper={(swiper) => console.log(swiper)}
                    navigation={{
                        nextEl:".custom-next",
                        prevEl:".custom-prev",
                    }}
                    className="w-full flex justify-center"
                >   

                    
                {
                    people_list.map(
                        (item)=>{
                            return(
                                
                                <SwiperSlide className="felx justify-center  items-centertext-center">
                                <div className="flex flex-col justify-center items-center gap-2">
                                    <img className="h-18 w-18 rounded-[50%] object-cover" src={item.image}/>
                        
                                    <p className="font-bold text-gray900">{item.name}</p>
                                    <p className='text-gray600'>{item.job}{item.id}</p>
                        
                                </div>
                                </SwiperSlide>
                            )
                        }
                    )
                }
            
                </Swiper>
                <button className='custom-prev'><img src={right_arrow}/></button>
            </div>
        </div>
        </div>
    );
    function chage_comment(props) {
        setComment(props)
    }

}

function Features(props) {

    if (props.scroll===true) {
        const section=document.getElementById("features");
        section.scrollIntoView({behavior:"smooth"})
        
    }
    return(
        <div  className="flex flex-col justify-center items-center w-full gap-16 mt-20 relative">
            <div id='features' className=" absolute top-[-80px]"></div>

            <div className="flex flex-col text-center sm:w-[60vw] gap-4 mx-4">
                <p className="text-brand600 text-s font-bold">Features</p>
                <p className="text-gray900 font-bold text-2xl">Your AI-Powered Social Media Creator</p>
                <p className="text-gray600">Contentor is an easy-to-use solution that helps users create high-quality social media content affordably, using AI. It supports platforms like Twitter, TikTok, WhatsApp, Instagram, Snapchat, and YouTube.</p>
            </div>
            <div className="flex sm:flex-row flex-col flex-wrap w-full sm:w-[80%] justify-center items-center gap-7 p-3 ">
                {features_list.map(
                    (item)=>{
                        return(
                            <div className="flex flex-col justify-center items-center text-center gap-2 sm:w-[30%]">
                            <img className="w-14" src={item.icon}/>
                            <p className="font-bold text-gray900">{item.name}</p>
                            <p className=" text-[15px] text-gray600">{item.caption}</p>
                        </div>
                        );
                    }
                )}
            </div>

        <Features_slider/>
        </div>
    )
}



export default Features;