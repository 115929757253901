import card1 from "./../assets/images/card1.png"
import card2 from "./../assets/images/card2.png"
import card3 from "./../assets/images/card3.png"
import card4 from "./../assets/images/card4.png"
import card5 from "./../assets/images/card5.png"
import card6 from "./../assets/images/card6.png"
import card7 from "./../assets/images/card7.png"
import card8 from "./../assets/images/card8.png"
import x from "./../assets/images/x.svg"


function main(props) {
    return(
        <div id={props.id} className={`w-[170px] h-[170px] p-2 border-2  rounded-2xl flex flex-col justify-between ${props.border}`}>
            <img src={props.image}/>
            <p className="text-[9px] text-gray600">Get ready for a spooky good time this Halloween! Dress up in your favorite costume and indulge in swe</p>
            <div className="flex items-center gap-1">
                <img className="h-[20px]" src={props.icon}/>
                <p className="text-[9px] text-gray600">Pavel Jones</p>
            </div>
        </div>
    )
}

export default main