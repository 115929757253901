import checkIcon from "./../assets/icons/check.svg";
import telegram from "./../assets/icons/telegram.svg";
import whatsapp from "./../assets/icons/whatsapp.svg";
import tiktok from "./../assets/icons/tiktok.svg";
import instagram from "./../assets/icons/instagram.svg";
import youtube from "./../assets/icons/youtube.svg";

var card_info =[
    {price:"free",status:"Basic plan", properties:["3 free run per day","All Social Media","No required login","Trends tool"]},
    {price:"$2/mo",status:"Premium plan", properties:["High-quality contents","Coming up with the best ideas","Variety and creativity","Infinite capacity","enhance your online presence"]},
]

function pricing(props) {
    if (props.scroll===true) {
        const section=document.getElementById("pricing");
        section.scrollIntoView({behavior:"smooth"})
        
    }
    return(
        <div className="w-full mt-20 relative">
            <div id="pricing"  className="absolute top-[-80px]"></div>

            <div className="sm:w-[80%] sm:px-28 px-4 flex flex-col gap-4">
                <p className="text-brand600 text-l">pricing</p>
                <p className="text-gray-900 font-bold text-3xl">Access Top-Notch Social Media Content for Only $2!</p>
                <p className="text-gray-500">Get the best deal for crafting social media posts! We are proud to provide the best rates for social media content. With our unbeatable rates, enhance your online presence with premium content for just $2.</p>
            </div>


            <div className="w-full flex sm:flex-row flex-col items-center sm:items-stretch justify-center gap-4 mt-24 ">

                {/* card Box */}
                {card_info.map(
                    (item)=>{
                        return (
                             <div className={`${item.price==="$2/mo"?"border-2 border-brand600":""} py-6 px-4 sm:w-[350px] w-[85%] flex flex-col justify-between items-center border-2  shadow-xl transition duration-500 transform hover:-translate-y-[2px]  rounded-xl gap-10`}>
                                 <div className="flex flex-col gap-4">
                                    <p className="font-bold text-4xl text-gray900">{item.price}</p>
                                    <p>{item.status}</p>
                                </div>
                            <div className="w-full flex flex-col gap-3">
                                {item.properties.map(
                                    (property)=>{
                                        return (
                                            <div className="flex gap-3"><img src={checkIcon}/><p>{property}</p></div>

                                        )
                                    }
                                )}

                             </div>
                    
                    <button className="bg-brand600 py-2 w-[80%] text-white rounded-lg">Get started</button>

                </div>
                        );
                    }
                )}
                {/* -----------------> */}

            </div>

            <div className="flex flex-col justify-center items-center mt-32 gap-6 px-4">
                <p className="text-gray-600"> WE CREATE CONTENT FOR THESE SOCIAL MEDIA</p>
                <div className="sm:flex flex-wrap gap-10 justify-center grid grid-cols-2  ">
                    <img className="sm:w-[15%] w-[50%%]" src={youtube}/>
                    <img className="sm:w-[15%] w-[50%%]" src={telegram}/>
                    <img className="sm:w-[15%] w-[50%%]" src={instagram}/>
                    <img className="sm:w-[15%] w-[50%%]" src={tiktok}/>
                    <img className="sm:w-[15%] w-[50%%]" src={whatsapp}/>
                </div>
            </div>
        </div>
    )
}

export default pricing;