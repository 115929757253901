import logo from "./../assets/icons/logo.svg"
import dribbble from "./../assets/icons/dribbble.svg";
import facebook from "./../assets/icons/facebook.svg";
import github from "./../assets/icons/github.svg";
import linkedin from "./../assets/icons/linkedin.svg";
import x from "./../assets/icons/x.svg";
import social from "./../assets/icons/Social icon (1).svg";
import { Link } from "react-router-dom";
import email from "./../assets/icons/email.svg"

var menu_names=[
    {name:"Home",link:'/'},
    {name:"Trends",link:'/#trends'},
    {name:"Why choose us",link:'/#why_choose_us'},
    {name:"Features",link:'/#features'},
    {name:"Testimonial",link:'/#testimonial'},
    {name:"Pricing",link:'/#pricing'},
    {name:"FAQ",link:'/#faq'},
    {name:"Contact us",link:'contact-us'},
    {name:"About us",link:'about-us'},
]

function Footer() {
    return(
        <div className="w-full flex justify-center mt-10">
            <div className="w-[80%]  flex flex-col">
                {/* line */}
                <div className="h-[2px] w-full bg-gray300 shadow justify-items-center mb-10"></div>   

                <div className="flex flex-col w-full gap-7">
                    <Link to="/" className="flex items-center gap-1vw">
                        <img src={logo} />
                        <p>CONTENTOR</p>
                    </Link>

                    <p className="sm:w-[30vw] w-full text-gray600">
                        Design amazing digital experiences that create more happy in the world.
                    </p>
                    <ul className=" sm:flex flex-wrap gap-2vw text-gray600 grid grid-cols-2">
                        {
                            menu_names.map((item)=>{return <li><Link to={item.link} className="hover:underline hover:text-stone-700 font-bold" alt={item}>{item.name}</Link></li>})
                        }
                        
                    </ul>

                </div>
                <div className="h-[1px] w-full bg-gray200 shadow justify-items-center mt-10"></div>

                <div className="flex flex-col sm:flex-row justify-between py-10 w-full gap-4">
                    <p className="text-gray500 text-center">© 2024 Contentor.ca All rights reserved.</p>
                    <div className="flex gap-x-4 justify-center">
                        <a href="#"><img src={dribbble} alt="dribbble"/></a>
                        <a href="#"><img src={facebook} alt="facebook"/></a>
                        <a href="#"><img src={github} alt="github"/></a>
                        <a href="#"><img src={linkedin} alt="linkedin"/></a>
                        <a href="#"><img src={x} alt="x"/></a>
                        <a href="#"><img src={social} alt="Social"/></a>
                    </div>
                </div>

                
            </div>
        </div>
    )
}

function Footer_prime() {
    return(
        <>
            <div className="flex w-full justify-between sm:px-8 sm:py-4 p-4 text-gray600 fixed bg-white  bottom-0 ">
                <p>© Contentor.ca</p>
                <div className="flex gap-2">
                    <img className="w-5" src={email}/>
                    <p>help@example.com</p>
                </div>
            </div>
        </>
    )
}

function main(props) {
    return props.status==="prime"?<Footer_prime/>:<Footer/>
    
}

export default main;