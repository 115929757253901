


function aboutUs() {
    return(
        <section className="flex flex-col gap-24 sm:px-16 px-4">
            <div className="flex flex-col gap-3 text-center items-center">
                <small className="text-brand600 font-bold">About Us</small>
                <p className="font-bold text-gray900 text-3xl">About the Contentor</p>
                <p className="text-gray600 sm:w-[70%] w-full">
                Contentor is your one-stop solution for creating engaging social media content. Powered by the latest AI technology, our platform simplifies content creation, allowing you to focus on what matters most: building your brand.
                </p>
            </div>
            
            <div className="flex  sm:flex-row flex-col justify-center items-center gap-20">
                <div className="flex flex-col gap-1">
                    <b className="text-brand600">Our Mission</b>
                    <p className="text-gray600">To empower individuals and businesses to create high-quality social media content effortlessly.</p>
                </div>
                <div className="flex flex-col gap-1">
                    <b className="text-brand600">Our Vision</b>
                    <p className="text-gray600">To be the leading AI-powered content creation platform, transforming the way social media content is produced.</p>
                </div>
            </div>
        </section>
    )
};

export default aboutUs