import './styles/global.css'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ContactUs from './pages/contactUs';
import Home from './pages/Landing_page';
import Header from "./components/header";
import Footer from "./components/footer";
import Signup from "./pages/signup";
import Login from "./pages/signin";
import AboutUs from "./pages/aboutUs";

function AppContent() {
  const location = useLocation();
  
  const hideHeaderFooter = location.pathname === '/signin' || location.pathname === '/signup';

  return (
    <>
      {hideHeaderFooter? <Header status="prime"/> : <Header status=""/>}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/signin' element={<Login />} />
        <Route path='/about-us' element={<AboutUs />} />
      </Routes>
      {hideHeaderFooter? <Footer status="prime"/> : <Footer status=""/>}

    </>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;