import { Link } from "react-router-dom";
function login() {
    return (
    <div className="w-full flex justify-center py-4 h-[85vh] items-center mb-12">
        <div className="flex flex-col gap-4 sm:w-[80%] max-w-[400px] w-[90%]">
            <div className="flex gap-1 flex-col">
                <p className="text-gray900 font-bold text-2xl">Sign in</p>
                <p className=" text-l text-gray-500">Welcome back! Please enter your details.</p>
            </div>

            <form className="flex flex-col gap-6">
                <div className="flex flex-col gap-2">
                    <label className="text-gray700 font-bold" htmlFor="name">Email or username*</label>
                    <input className="outline-none p-3 w-full border-2 border-gray300 rounded-xl" id="name" placeholder="Enter your email or username" type="text"></input>
                </div>

                <div className="flex flex-col gap-2">
                    <label className="text-gray700 font-bold" htmlFor="pass">Password*</label>
                    <input className="outline-none p-3 w-full border-2 border-gray300 rounded-xl" id="pass" placeholder="Create a password" type="password"></input>
                </div>

                <button className=" w-full bg-brand600 py-2 rounded-md text-white duration-500 hover:scale-[1.03] hover:shadow-lg" type="submit">Sign in</button>
                <p className="text-center text-gray600">Don’t have an account? <Link className=" cursor-pointer" to={"/signup"}><b className="text-brand600">Sign Up</b></Link></p>
            </form>
        </div>

    </div>
    )
}

export default login;